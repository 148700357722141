@use '../../globals/Fonts';
@use '../../globals/Colors';
@use '../../globals/Defaults.scss';


.slider {

  .swiper {
    overflow: visible;
  }

  .swiper-horizontal {

    .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: -30px;
    }

    .swiper-pagination-bullet-active {
      background: #5d5d5d;
      transform: scale(1.3);
      transition-duration: 1s;
    }

    .swiper-button-prev {
      left: 20px;

      @media (min-width: 1575px) {
        right: 30px;
      }
    }

    .swiper-button-next {
      right: 20px;

      @media (min-width: 1575px) {
        right: 30px;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      transition-duration: 0.2s;
      color: #e5e5e5;
      margin-top: -22px;
    }

  }

  &__title {
    @include Fonts.fontExtraBold;
    color: Colors.$blue;
    font-size: 25px;

    @media (min-width: 960px) {
      font-size: 40px;
    }
  }

}
