@use '../../globals/Fonts';
@use '../../globals/Colors';
@use '../../globals/Defaults.scss';

.App {
  @include Fonts.fontDefault;
}

.pageContent {
  height: auto;

}
